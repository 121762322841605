import React, { Component } from "react";
import { Row, Col, Card, CardBody, Media, Button, Form, FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import avatar1 from "../../assets/images/profile-icons/usergrren.png";
import { ApiUrl, WebUrl } from "../../config";
import { dcrypt, uToken } from "../../useToken";
import { toast, Flip } from "react-toastify";
import EmailSideBar from "./email-sidebar";
import swal from "sweetalert";
import Froala from "../Froala";

import EmailToolbar from "./email-toolbar";
const defaultMessage = "";
class EmailRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      replies: [],
      loading: true,
      error: null,
      replyContent: '',
      selectedClient: {},
      isReplying: false,
      files: [],
      isSearch: false,
      emails: [],
      selectedEmails: new Set(),
      files: [],
      existing: [],
      labels: [],
      customMessage: '',
      isClient: true,
      labelDropdownOpen: false,
      isForwarding: false,
      readCount: 0,
    };
  }
  componentDidMount() {
    this.getEmailRead();
  }
  htmlToText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };


  handleReplyInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleReplyBox = () => {
    const { email } = this.state;
    if (!this.state.isReplying) {
      const emailDate = email.date ? new Date(email.date).toLocaleString() : new Date().toLocaleString();
      const replyTemplate = `\n\n\n---------- Original message ---------\nFrom: ${email.name} <${email.froms}>\nDate: ${emailDate}\nSubject: ${email.subject}\nTo: ${email.tos}\n\n${this.htmlToText(email.body)}`;
      this.setState({
        isReplying: true,
        replyContent: `${replyTemplate}\n\n${this.state.replyContent || ''}`,
      });
    } else {
      this.setState({ isReplying: false, replyContent: '' });
    }
  };
  

  handleReplySubmit = (event) => {
    event.preventDefault();
    const { email, replyContent } = this.state;
    if (!replyContent.trim()) {
      toast("Reply content cannot be empty.", {
        hideProgressBar: true,
        position: "top-center",
        transition: Flip,
        className: 'toast-custom-cls error',
      });
      return;
    }
    const formData = new FormData();
    formData.append('report_id', email.report_id);
    formData.append('reply_content', replyContent);
    formData.append('tos', email.froms);
    this.state.files.forEach(file => {
      formData.append('fileAttachments[]', file);
    });

    this.setState({ isLoading: true });
    fetch(`${ApiUrl}aio/reply/email`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uToken()}`,
      },
      body: formData,
    })
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || "Network response was not ok.");
        }
        return data;
      })
      .then((data) => {
        this.setState({ isLoading: false });
        if (data.status) {
          toast("Reply sent and count updated successfully!", {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls success',
          });
          setTimeout(() => {
            this.props.history.push('/email/sent');
          }, 2000);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error',
          });
          console.log("Error in reply response:", data.message);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast(`Error sending reply: ${error.message}`, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error',
        });
      });
  };

  handleEmailSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('tos', this.state.selectedClient.email);
    formData.append('client_id', this.state.selectedClient.client_id);
    formData.append('type', 'email');
    formData.append('setting_id', this.state.verifiedFromEmailId);
    const subject = this.state.subject ? this.state.subject : '(no subject)';
    formData.append('subject', subject);
    const customTemplateElement = document.getElementById("custom-template");
    const bodyContent = this.state.html !== "" && customTemplateElement
      ? customTemplateElement.innerHTML
      : this.state.isForwarding
        ? this.state.forwardContent
        : this.state.smsBody;
    formData.append('body', bodyContent);
    this.state.files.forEach(file => {
      formData.append('fileAttachments[]', file);
    });
    fetch(`${ApiUrl}company/email/activity`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${uToken()}`,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          this.setState({
            showAlert: true,
            alertType: 'success',
            alertTitle: 'Success',
            alertText: 'Email sent successfully.',
          });
          setTimeout(() => {
            this.props.history.push('/email/sent');
          }, 2000);
        } else {
          this.setState({
            showAlert: true,
            alertType: 'error',
            alertTitle: 'Error',
            alertText: data.message,
          });
        }
      })
      .catch(error => {
        this.setState({
          showAlert: true,
          alertType: 'error',
          alertTitle: 'Error',
          alertText: 'An error occurred while sending the email.',
        });
      });
  };

  handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    this.setState(prevState => ({
      files: [...prevState.files, ...selectedFiles]
    }));
  };
  removeFile = (index) => {
    this.setState(prevState => {
      const updatedFiles = prevState.files.filter((file, i) => i !== index);
      return { files: updatedFiles };
    });
  };

  getEmailRead = () => {
    const { report_id } = this.props.match.params;
    const decryptedReportId = dcrypt(report_id);

    fetch(`${ApiUrl}aio/report/read/email?report_id=${decryptedReportId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response Data: ", data);
        if (data.status === true) {
          const labels = data.data.label_name
            ? [{
              name: data.data.label_name,
              color_code: data.data.color_code,
            }]
            : [];

          this.setState({
            email: {
              ...data.data,
              labels,
            },
            loading: false,
          });
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: 'toast-custom-cls error'
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast("Error fetching email", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: 'toast-custom-cls error'
        });
      });
  };


  handleChange = (event) => {
    const newValue = event.target.value;
    this.setState({ forwardContent: newValue });
  };


  toggleForwardBox = () => {
    if (!this.state.isForwarding) {
      const { email } = this.state;
      const emailDate = email.date ? new Date(email.date).toLocaleString() : new Date().toLocaleString();
      const forwardTemplate = `\n\n\n---------- Forwarded message ---------\nFrom: ${email.name} <${email.froms}>\nDate: ${emailDate}\nSubject: ${email.subject}\nTo: ${email.tos}\n\n${this.htmlToText(email.body)}`;
      this.setState({
        isForwarding: true,
        forwardContent: `${defaultMessage}\n\n${forwardTemplate}`,
      });
    } else {
      this.setState({ isForwarding: false, forwardContent: defaultMessage });
    }
  };

  searchClient = (search) => {
    if (search !== "") {
      fetch(`${ApiUrl}` + "get/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: search }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === true) {
            this.setState({
              existing: response.data.records,
              isClient: response.data.records.length > 0,
            });
          } else {
            toast(response.message, {
              hideProgressBar: true,
              position: "top-center",
              transition: Flip,
              className: 'toast-custom-cls error',
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      this.setState({
        existing: [],
        isClient: true,
      });
    }
  };
  toggleSearch = () => {
    this.setState((prevState) => ({ isSearch: !prevState.isSearch }));
  };

  toggleShow = () => {
    this.setState((prevState) => ({ show: !prevState.show }));
  };

  handleInputChange = (e) => {
    const value = e.target.value;
    this.setState({ selectedClient: value }, () => {
      this.searchClient(value);
    });
  }
  handleSelectClient = (row) => {
    this.setState({
      selectedClient: row,
      existing: []
    }, () => {
      console.log('State updated:', this.state);
    });
  }

  handleLabelSelect = (labelId) => {
    const { report_id } = this.props.match.params;
    const reportIds = dcrypt(report_id);

    fetch(`${ApiUrl}assign/gmail/lebel`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ label_id: labelId, report_ids: reportIds }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          const successMessage = labelId ? "Label applied successfully" : "Label removed successfully";
          toast.success(successMessage, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
          });
          this.getEmailRead();
        } else {
          this.showError(response.message);
        }
      })
      .catch(() => this.showError("Error applying or removing label"));
  };


  toggleLabelDropdown = () => {
    this.setState((prevState) => ({
      labelDropdownOpen: !prevState.labelDropdownOpen,
    }));
  };

  handleDelete = () => {
    const { report_id } = this.props.match.params;
    const decryptedReportId = dcrypt(report_id);

    swal({
      title: "Are you sure?",
      text: "You want to delete this email.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmation) => {
      if (confirmation) {
        fetch(`${ApiUrl}aio/delete/email`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${uToken()}`,
          },
          body: JSON.stringify({ report_id: decryptedReportId }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok.");
            }
            return response.json();
          })
          .then((data) => {
            if (data.status === true) {
              swal("Success", "Email deleted successfully!", "success")
                .then(() => {
                  this.props.history.push('/email/inbox');
                });
            } else {
              swal("Error", data.message, "error");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            swal("Error", "Error deleting email", "error");
          });
      }
    });
  };


  render() {
    const { labels, labelDropdownOpen, isClient, selectedClient, existing, email, loading, error, replyContent, isReplying, replies, readCount, isForwarding, forwardContent } = this.state;
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!email) return <div>No email found</div>;
    const styles = {
      dropdownToggle: {
        fontSize: "17px",
        padding: "5px 10px",
      },
      dropdownMenu: {
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        border: "1px solid #e0e0e0",
        padding: "10px",
        minWidth: "200px",
        fontSize: "14px",
        left: "-170px !important",
      },
      dropdownItemHeader: {
        fontWeight: "500",
        fontSize: "13px",
        paddingBottom: "6px",
        paddingTop: "4px",
        paddingLeft: "10px",
        backgroundColor: "#fafafa",
        color: "#757575",
      },
      dropdownItem: {
        padding: "6px 12px",
        borderRadius: "4px",
        transition: "background-color 0.2s ease-in-out",
      },
      noRecords: {
        textAlign: "center",
        padding: "20px",
        fontSize: "18px",
        color: "#888",
        borderTop: "1px solid #ddd",
        backgroundColor: "#f9f9f9",
      },
      iconStyle: {
        fontSize: "24px",
        color: "#888",
        display: "block",
        marginBottom: "10px",
      },
      labelBadge: {
        backgroundColor: "#f0f0f0",
        color: "#000",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: "5px",
        fontSize: "12px",
      },
    };
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <EmailSideBar onLabelsFetched={(labels) => this.setState({ labels })} />
            <div className="email-rightbar mb-3">
              <Card>
                <CardBody className="pt-0">
                  <div className="d-flex align-items-center EmailMedia">
                    <Media className="align-items-center">
                      <span style={{ marginTop: '-22px' }} className="mdi-48px mdi mdi-account-circle d-flex me-2 rounded-circle"></span>
                      <div style={{ flex: 1 }}>
                        <h4 style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          margin: 0,
                          color: '#202124',
                        }}>
                          {email.name || 'Unknown Sender'}
                        </h4>
                        <p style={{
                          fontSize: '13px',
                          color: '#5f6368',
                          margin: 0,
                        }}>
                          <span style={{ color: "darkgray" }}>From:</span> {email.froms || 'No email address'}
                        </p>

                        <p style={{
                          fontSize: '13px',
                          color: '#5f6368',
                          margin: 0,
                        }}>
                          <span style={{ color: "darkgray" }}>To:</span> {email.tos || 'No email address'}
                        </p>
                      </div>
                    </Media>

                    <div style={{ marginTop: "26px" }} className="d-flex justify-content-between align-items-center ms-auto">
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        {!isReplying && !isForwarding && (
                          <>
                            <Button
                              color="white"
                              className="btn-reply waves-effect"
                              onClick={this.toggleReplyBox}
                              style={{
                                borderRadius: '20px',
                                padding: '6px 16px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <i style={{ fontSize: "16px", marginRight: "3px" }} className="mdi mdi-reply"></i> Reply
                            </Button>

                            <Button
                              color="white"
                              className="btn-forward waves-effect"
                              onClick={this.toggleForwardBox}
                              style={{
                                borderRadius: '20px',
                                padding: '6px 16px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <i className="mdi mdi-reply" style={{ transform: 'scaleX(-1)', fontSize: '16px', marginRight: '3px' }}></i> Forward
                            </Button>
                          </>
                        )}
                      </div>
                      <p style={{
                        fontSize: '12px',
                        color: '#5f6368',
                        margin: 0,
                      }}>
                        ( Date: {email.created_at || "Date not available"} )
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div className="d-flex align-items-center">
                      <h4 className="mt-0 font-size-18 text-dark mb-0">
                        {email.subject || "(no subject)"}
                      </h4>

                      {email.labels && email.labels.length > 0 ? (
                        <span
                          style={{
                            marginLeft: '10px',
                            fontSize: '14px',
                            color: '#5f6368',
                          }}
                        >
                          {email.labels.map((label, index) => (
                            <span
                              key={index}
                              style={{
                                backgroundColor: label.color_code,
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '12px',
                                margin: '0 5px',
                                fontSize: '12px',
                                display: 'inline-block',
                              }}
                            >
                              {label.name}
                            </span>
                          )).reduce((prev, curr) => [prev, ', ', curr])}
                        </span>
                      ) : null}
                    </div>

                    <div className="d-flex align-items-center ml-auto">
                      <Dropdown isOpen={labelDropdownOpen} toggle={this.toggleLabelDropdown} style={{ marginLeft: '10px' }}>
                        <DropdownToggle
                          style={styles.dropdownToggle}
                          tag="span"
                          className="mdi mdi-label cursor-pointer"
                        />
                        <DropdownMenu end style={styles.dropdownMenu}>
                          <DropdownItem header style={styles.dropdownItemHeader}>Label as</DropdownItem>
                          <DropdownItem
                            key="unassign"
                            onClick={() => this.handleLabelSelect(0)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '10px 15px',
                              cursor: 'pointer',
                            }}
                          >
                            <span
                              className="mdi mdi-label-off"
                              style={{ marginRight: '8px', fontSize: '16px' }}
                            ></span>
                            Unassign Label
                          </DropdownItem>
                          {labels.length > 0 ? (
                            labels.map((label) => (
                              <DropdownItem
                                key={label.id}
                                onClick={() => this.handleLabelSelect(label.id)}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '10px 15px',
                                  cursor: 'pointer',
                                }}
                              >
                                <span
                                  className="mdi mdi-label"
                                  style={{
                                    marginRight: '8px',
                                    color: label.color_code,
                                    fontSize: '16px',
                                  }}
                                ></span>
                                {label.name}
                              </DropdownItem>
                            ))
                          ) : (
                            <DropdownItem>No labels found</DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        type="button"
                        onClick={this.handleDelete}
                        style={{
                          background: 'transparent',
                          border: 'none',
                          padding: '5px 10px',
                          cursor: 'pointer',
                          color: '#5b5757',
                          fontSize: '18px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span className="mdi mdi-delete" style={{ fontSize: '18px' }}></span>
                      </Button>


                    </div>
                  </div>

                  {/* <EmailToolbar hidePagination={true} hideSearch={true} hideDeleteButton={true} /> */}

                  <div className="EmailContent">
                    <div dangerouslySetInnerHTML={{ __html: email.body }}></div>
                    {email.attachments && email.attachments.length > 0 && (
                      <div className="mt-3">
                        <h5 style={{ fontWeight: 'bold' }}>Attachments:</h5>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}>
                          {email.attachments.map((attachment, index) => {
                            const fileExtension = attachment.path.split('.').pop().toLowerCase();
                            const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);
                            const isWord = fileExtension === 'doc' || fileExtension === 'docx';
                            const isPdf = fileExtension === 'pdf';
                            const isText = fileExtension === 'txt';
                            const isCsvOrExcel = ['csv', 'xls', 'xlsx'].includes(fileExtension);

                            const attachmentUrl = attachment.path.startsWith('http')
                              ? attachment.path
                              : `${WebUrl}/storage/app/public/attachments/${attachment.path.split('/').pop()}`;

                            return (
                              <div
                                key={index}
                                style={{
                                  width: '150px',
                                  height: '130px',
                                  border: '1px solid #ddd',
                                  borderRadius: '5px',
                                  overflow: 'hidden',
                                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                  position: 'relative',
                                  textAlign: 'center',
                                  backgroundColor: '#f9f9f9',
                                }}
                                className="attachment-item"
                              >
                                <a
                                  href={attachmentUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                  <div style={{ height: '90px', position: 'relative', padding: '10px' }}>
                                    {isImage ? (
                                      <img
                                        src={attachmentUrl}
                                        alt={attachment.original_name}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '100%',
                                          backgroundColor: '#f1f1f1',
                                        }}
                                      >
                                        {isPdf && (
                                          <span
                                            className="mdi mdi-file-pdf"
                                            style={{
                                              fontSize: '48px',
                                              color: '#D50032',
                                            }}
                                          ></span>
                                        )}
                                        {isWord && (
                                          <span
                                            className="mdi mdi-file-word"
                                            style={{
                                              fontSize: '48px',
                                              color: '#3F51B5',
                                            }}
                                          ></span>
                                        )}
                                        {isText && (
                                          <span
                                            className="mdi mdi-file-document"
                                            style={{
                                              fontSize: '48px',
                                              color: '#4B8DF8',
                                            }}
                                          ></span>
                                        )}
                                        {isCsvOrExcel && (
                                          <span
                                            className="mdi mdi-file-excel"
                                            style={{
                                              fontSize: '48px',
                                              color: '#4CAF50',
                                            }}
                                          ></span>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      background: '#f9f9f9',
                                      borderTop: '1px solid #ddd',
                                      padding: '5px',
                                      position: 'absolute',
                                      bottom: 0,
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#333',
                                      }}
                                    >
                                      {attachment.original_name}
                                    </div>
                                    <div
                                      style={{
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: '#FF5722',
                                        clipPath: 'polygon(100% 0, 0 100%, 100% 100%)',
                                      }}
                                    ></div>
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                  </div>

                  {readCount > 0 && replies.length > 0 && (
                    <div className="mt-4">
                      {replies.map((reply, index) => (
                        <div key={index} className="mb-3" style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px' }}>
                          <Media>
                            <img
                              className="d-flex me-3 rounded-circle avatar-sm"
                              src={avatar1}
                              alt="avatar"
                            />
                            <Media body className="align-self-center">
                              <div style={{ backgroundColor: '#f1f3f4', padding: '10px', borderRadius: '8px' }}>
                                <p>{reply.content}</p>
                              </div>
                              <small className="text-muted">{reply.date}</small>
                            </Media>
                          </Media>
                        </div>
                      ))}
                    </div>
                  )}
                  {isReplying && (
                    <div className="mt-5">
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <i className="fas fa-share" style={{ fontSize: '16px', color: '#008037', transform: 'scaleX(-1)' }}></i>
                        <strong style={{ marginLeft: '8px' }}>Reply to: </strong>
                        <span style={{ marginLeft: '8px', color: '#555' }}>{email.name} ({email.froms})</span>
                      </div>
                      <Form onSubmit={this.handleReplySubmit}>
                        <label htmlFor="replyContent" style={{ display: 'none' }}>Reply Content</label>
                        <textarea
                          name="replyContent"
                          id="replyContent"
                          rows="10"
                          value={replyContent}
                          onChange={this.handleReplyInputChange}
                          placeholder="Write your reply here..."
                          style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '10px', width: '100%', marginTop: '10px' }}
                        />
                        <input type="hidden" name="activity" value={"Email: sent"} />
                        <div className="mb-3 mt-3">
                          <label>Attach File(s)</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={this.handleFileChange}
                          />
                        </div>

                        {this.state.files.length > 0 && (
                          <div style={{ marginBottom: '16px' }}>
                            <h6 style={{ fontSize: '14px', marginBottom: '8px', color: '#5f6368' }}>Attached File(s)</h6>
                            <ul style={{ padding: 0, listStyleType: 'none' }}>
                              {this.state.files.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '8px 12px',
                                    marginBottom: '8px',
                                    backgroundColor: '#f1f3f4',
                                    borderRadius: '12px',
                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                    maxWidth: '500px',
                                    position: 'relative',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      color: '#202124',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '500px',
                                    }}
                                  >
                                    {file.name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => this.removeFile(index)}
                                    style={{
                                      border: 'none',
                                      background: 'none',
                                      cursor: 'pointer',
                                      padding: '4px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'absolute',
                                      right: '6px',
                                      top: '6px',
                                    }}
                                  >
                                    <span
                                      className="mdi mdi-close"
                                      style={{ fontSize: '18px', color: '#5f6368' }}
                                    ></span>
                                  </button>
                                </div>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center mt-3 ReplyBtn">
                          <div>
                            <Button
                              color="primary"
                              type="submit"  // Change to type="submit" to trigger form submission
                              disabled={this.state.isLoading}
                              style={{
                                border: 'none',
                                borderRadius: '20px',
                                padding: '6px 16px'
                              }}
                            >
                              {this.state.isLoading ? 'Sending...' : 'Send'}
                            </Button>{' '}
                            <Button
                              color="secondary"
                              type="button"  // Keep this as a regular button
                              onClick={this.toggleReplyBox}
                              style={{
                                border: '0',
                                borderRadius: '20px',
                                padding: '6px 16px',
                              }}
                            >
                              Discard
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}

                  {isForwarding && (
                    <div className="mt-5">
                      <Form onSubmit={this.handleEmailSubmit}>
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                          <i className="fas fa-share" style={{ position: 'absolute', left: '10px', top: '10px', fontSize: '16px', color: '#008037' }}></i>
                          <input
                            style={{
                              paddingLeft: '35px',
                              borderColor: 'hsl(0, 0%, 80%)',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderRadius: '4px',
                              width: '100%',
                              height: '40px',
                              fontSize: '14px',
                            }}
                            type="text"
                            placeholder="Enter recipient email"
                            value={selectedClient.email}
                            onChange={this.handleInputChange}
                            onBlur={() =>
                              setTimeout(() => {
                                this.setState({ existing: [] });
                              }, 1000)
                            }
                          />
                        </div>
                        <ul style={{ position: 'static' }} className="client-search-bar">
                          {!isClient && <li className="text-primary">No data found</li>}
                          {existing.map((row, i) => (
                            <li key={i} onClick={() => this.handleSelectClient(row)}>
                              <div className="d-flex align-items-center">
                                <div className="info me-3">
                                  <i className="fas fa-user me-1"></i>
                                  {row.first_name} {row.last_name}
                                </div>
                                <div className="info d-flex align-items-center">
                                  <i className="fas fa-envelope me-1"></i>
                                  {row.email}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <textarea
                          name="body"
                          id="forwardContent"
                          rows="10"
                          value={this.state.forwardContent}
                          placeholder="Enter your message here"
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '10px',
                            width: '100%',
                            fontSize: '14px',
                          }}
                          onChange={this.handleChange}
                        />
                        <div className="mb-3 mt-3">
                          <label>Attach File(s)</label>
                          <input
                            type="file"
                            className="form-control"
                            multiple
                            onChange={this.handleFileChange}
                          />
                        </div>

                        {this.state.files.length > 0 && (
                          <div style={{ marginBottom: '16px' }}>
                            <h6 style={{ fontSize: '14px', marginBottom: '8px', color: '#5f6368' }}>Attached File(s)</h6>
                            <ul style={{ padding: 0, listStyleType: 'none' }}>
                              {this.state.files.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '8px 12px',
                                    marginBottom: '8px',
                                    backgroundColor: '#f1f3f4',
                                    borderRadius: '12px',
                                    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                    maxWidth: '500px',
                                    position: 'relative',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: '14px',
                                      color: '#202124',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '500px',
                                    }}
                                  >
                                    {file.name}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => this.removeFile(index)}
                                    style={{
                                      border: 'none',
                                      background: 'none',
                                      cursor: 'pointer',
                                      padding: '4px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      position: 'absolute',
                                      right: '6px',
                                      top: '6px',
                                    }}
                                  >
                                    <span
                                      className="mdi mdi-close"
                                      style={{ fontSize: '18px', color: '#5f6368' }}
                                    ></span>
                                  </button>
                                </div>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center mt-3 ForwardBtn">
                          <div>
                            <Button
                              type="submit"
                              style={{
                                border: 'none',
                                borderRadius: '20px',
                                padding: '6px 16px',
                                background: '#008037',
                                color: 'white',
                              }}
                            >
                              Forward
                            </Button>{' '}
                            <Button
                              color="secondary"
                              onClick={this.toggleForwardBox}
                              style={{
                                border: '0',
                                borderRadius: '20px',
                                padding: '6px 16px',
                              }}
                            >
                              Discard
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}


                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default EmailRead;