import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { ApiUrl } from "../../config";
import swal from "sweetalert";
import { bcrypt, uToken } from "../../useToken";

class EmailSideBar extends Component {
  state = {
    labels: [],
    modal: false,
    newLabel: '',
    editLabel: '',
    editIndex: null,
    dropdownOpen: null,
    labelColor: '#000000',
  };

  componentDidMount() {
    this.getLabelData();
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      newLabel: '',
      editLabel: '',
      editIndex: null,
      labelColor: '#000000'
    }));
  };

  handleFetchError = (error, message) => {
    console.error('Error:', error);
    swal({
      title: 'Error',
      text: message || 'Something went wrong. Please try again later.',
      icon: 'error'
    });
  };

  getLabelData = async () => {
    try {
      const response = await fetch(`${ApiUrl}get/gmail/label`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${uToken()}`
        },
      });
      const data = await response.json();

      if (data.status) {
        this.setState({ labels: data.data.labels });
        if (this.props.onLabelsFetched) {
          this.props.onLabelsFetched(data.data.labels);
        }
      } else {
        swal({ title: data.message, icon: "warning" });
      }
    } catch (error) {
      this.handleFetchError(error, 'Failed to fetch labels.');
    }
  }

  handleEditLabel = (label, index) => {
    this.setState({
      editLabel: label.name,
      labelColor: label.color_code || '#000000',
      editIndex: index,
      modal: true
    });
  };

  handleDropdownToggle = (index) => {
    this.setState(prevState => ({
      dropdownOpen: prevState.dropdownOpen === index ? null : index
    }));
  };

  submitPage = async (event) => {
    event.preventDefault();
    const { editLabel, editIndex, labels, labelColor } = this.state;

    const labelData = {
      name: editLabel,
      color_code: labelColor,
      id: editIndex !== null ? labels[editIndex].id : undefined
    };

    try {
      const response = await fetch(`${ApiUrl}add/gmail/label`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${uToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(labelData),
      });
      const data = await response.json();

      if (data.status) {
        const updatedLabels = editIndex !== null
          ? labels.map((label, index) => index === editIndex ? { ...label, name: editLabel, color_code: labelColor } : label)
          : [...labels, { name: editLabel, color_code: labelColor }];

        this.setState({ labels: updatedLabels });
        this.toggleModal();
        this.getLabelData();
      } else {
        swal({ title: data.message, icon: "warning" });
      }
    } catch (error) {
      this.handleFetchError(error);
    }
  };

  handleDeleteLabel = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirmation) => {
      if (confirmation) {
        try {
          const response = await fetch(`${ApiUrl}delete/gmail/label`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${uToken()}`,
            },
            body: JSON.stringify({ id }),
          });
          const data = await response.json();

          if (data.status) {
            this.setState(prevState => ({
              labels: prevState.labels.filter(label => label.id !== id)
            }));
            swal({ title: "Deleted!", text: "The label has been deleted.", icon: "success" });
          } else {
            swal({ title: data.message, icon: "warning" });
          }
        } catch (error) {
          this.handleFetchError(error, 'Failed to delete the label.');
        }
      }
    });
  }

  render() {
    const { unreadCount, location } = this.props;
    const { labels, modal, editLabel, dropdownOpen, labelColor } = this.state;

    return (
      <Card className="email-leftbar" style={{ padding: '15px' }}>
         <Link
          to="/email/compose"
          className="btn btn-primary  btn-custom btn-block waves-effect waves-light"
          style={{
            color: '#ffffff', 
            padding: '4px 0px', 
            borderRadius: '5px', 
            display: 'flex',
            alignItems: 'center', 
            fontSize: '14px',
            justifyContent: 'center',

          }}
        >
          <span
            className="mdi mdi-pencil"
            style={{
              marginRight: '8px', 
              fontSize: '14px',
              verticalAlign: 'middle', 
            }}
          ></span>
          Compose
        </Link>

        <div className="mail-list mt-3">
          <Link
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', padding: '10px 15px' }}
            to="/email/inbox"
            className={location.pathname === "/email/inbox" ? "active" : ""}
          >
            <span className="mdi mdi-inbox" style={{ marginRight: '8px', fontSize: '1.2rem' }} aria-hidden="true"></span>
            <span>Inbox</span>
            {unreadCount > 0 && <span className="ms-1">({unreadCount})</span>}
          </Link>
          <Link
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', padding: '10px 15px' }}
            to="/email/sent"
            className={location.pathname === "/email/sent" ? "active" : ""}
          >
            <span className="mdi mdi-send" style={{ marginRight: '8px', fontSize: '1.2rem' }} aria-hidden="true"></span>
            <span>Sent</span>
          </Link>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-4">
          <h5 className="mb-0">Labels</h5>
          <Button color="link" onClick={this.toggleModal} className="mdi mdi-plus" style={{ fontSize: "1.2rem", textDecoration: "none", padding: '2px 4px', paddingRight: '0px' }} />
        </div>

        <div className="mail-list mt-3">
          {labels.map((label, index) => (
            <div key={index} className="d-flex justify-content-between align-items-center">
              <Link
                style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', padding: '10px 15px', borderRadius: '5px' }}
                onClick={() => this.props.history.push('/email/sent/' + bcrypt(label.id))}
              >
                <span className="mdi mdi-label" style={{ marginRight: '8px', fontSize: '1.2rem', color: label.color_code || '#000000' }} aria-hidden="true"></span>
                <span>{label.name}</span>
              </Link>
              <div className="d-flex align-items-center">
                <Dropdown isOpen={dropdownOpen === index} toggle={() => this.handleDropdownToggle(index)}>
                  <DropdownToggle style={{ paddingRight: '3px' }} tag="span" className="mdi mdi-dots-vertical cursor-pointer" />
                  <DropdownMenu end>
                    <DropdownItem onClick={() => this.handleEditLabel(label, index)}>Edit</DropdownItem>
                    <DropdownItem onClick={() => this.handleDeleteLabel(label.id)}>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          ))}
        </div>

        <Modal isOpen={modal} toggle={this.toggleModal}>
          <form onSubmit={this.submitPage}>
            <ModalHeader toggle={this.toggleModal}>
              {this.state.editIndex !== null ? "Edit Label" : "Add New Label"}
            </ModalHeader>
            <ModalBody>
              <div className="mb-3">
                <label htmlFor="labelName" className="form-label">Label Name</label>
                <Input
                  type="text"
                  id="labelName"
                  name="label"
                  value={editLabel}
                  onChange={(e) => this.setState({ editLabel: e.target.value })}
                  placeholder="Enter label name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="labelColor" className="form-label">Label Color</label>
                <Input
                  type="color"
                  id="labelColor"
                  name="labelColor"
                  value={labelColor}
                  onChange={(e) => this.setState({ labelColor: e.target.value })}
                  required
                  style={{
                    padding: '0',
                    width: '100%',
                    height: '30px',
                    appearance: 'none',
                    backgroundColor: labelColor
                  }}
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <Button color="primary" type="submit">
                {this.state.editIndex !== null ? "Save Changes" : "Add Label"}
              </Button>
              <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>
      </Card>
    );
  }
}

export default withRouter(EmailSideBar);