import React, { useState, useEffect } from "react";
import { ApiUrl, AttachementsUrl } from "../../config";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Link, useHistory } from "react-router-dom";

const Badges = (props) => {
  const [id, setId] = useState(
    props.id ? props.id : props.match.params.id ? props.match.params.id : null
  );
  const [records, setRecords] = useState([]);
  const [total_badge, setTotalBadges] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    fetch(`${ApiUrl}get/module/badges`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id, status: "1" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records);
          setTotalBadges(response.data.total);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {!loading && records.length > 0 ? (
        <>
          <Carousel>
            {records.map((record, index) => (
              <Carousel.Item key={index}>
                <div style={{ textAlign: "center" }}>
                  <div className="SliderContent Dashboard">
                    <Link to={`/trainings/${record.module_encoded_link}`}>
                      <h5 className="ChangeColor">{record.module_title}</h5>
                    </Link>
                    <h5>{record.course_title}</h5>
                  </div>
                  <div className="BadgesLogo">
                    <img
                      className="d-block"
                      src={`${AttachementsUrl}user_${record.wl_admin}/training/${record.image}`}
                      alt={record.module_title}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.openModal();
                      }}
                    />
                  </div>
                </div>
              </Carousel.Item>
            ))}
            {/* <h3>{total_badge}</h3> */}
          </Carousel>
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => {
              props.openModal();
            }}
          >
            <h4>Total Badges Earned: {total_badge}</h4>
          </div>
        </>
      ) : (
        <img
          src="https://ws.profitcoach.app/crmdoc/default/images/certified-logo.png"
          alt="Icon"
        ></img>
      )}
    </>
  );
};

export default Badges;
