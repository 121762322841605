import React, { useContext, useRef, useState, useEffect } from "react";
import Knob from "../Campaign/knob";
import { bcrypt, dcrypt, uInfo, uToken } from "../../useToken";
import Loader from "react-loader";
import { ApiUrl, AttachementsUrl } from "../../config";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";

import ModuleBadges from "./Badges";
import UserProgressProfile from "./UserProgressProfile";

export default function TrainingDashboard(props) {
  const [value_prev, setvalue_prev] = useState(0);
  const [bookmark_data, setBookmarkData] = useState([]);

  const [progress, setProgress] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [module_data, setModuleData] = useState([]);

  const [lead_board, setLeadBoard] = useState([]);
  const [auth_user_rank, setAuthUserRank] = useState(0);
  const [module_preview, setModulePreview] = useState({});

  const [badgesModal, setBadgesModal] = useState(false);

  useEffect(() => {
    fetchLeaderBoardData();
    fetchBookMarkData();
    fetchModuleData();
  }, []);
  const columns = [
    { key: "sr", text: "#", sortable: false },
    { key: "step_title", text: "Title", sortable: false },
    { key: "quiz_passing_percentage", text: "Pass Rate (%)", sortable: false },
    {
      text: "Attempts",
      key: "attempts",
      cell: (record, index) => {
        return record.attempts;
      },
    },
    { key: "created_at", text: "Dated", sortable: false },
    { key: "score", text: "score (%)", sortable: false },

    {
      text: "Status",
      key: "quiz_pass_fail",
      cell: (record, index) => {
        if (record.quiz_pass_fail == "pass") {
          return (
            <p key={index} className="badge bg-success">
              Pass
            </p>
          );
        } else if (record.quiz_pass_fail == "fail") {
          return (
            <p key={index} className="badge bg-danger">
              Fail
            </p>
          );
        }
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50, 100],
    show_filter: false,
    show_pagination: false,
    button: {
      excel: false,
      print: false,
      csv: false,
    },
  };
  // const fetchData = () => {
  // 	setLoaded(false);
  // 	fetch(`${ApiUrl}` + "get/waybook/coach/dashboard", {
  // 		method: "POST",
  // 		headers: {
  // 			Accept: "application/json",
  // 			"Content-Type": "application/json",
  // 			Authorization: `Bearer ` + uToken(),
  // 		},
  // 		body: JSON.stringify({ user_id: uInfo().user_id }),
  // 	})
  // 		.then((response) => response.json())
  // 		.then((response) => {
  // 			if (response.status === true) {
  // 				setCompletedSteps(response.data.recently_completed_steps);
  // 				setIncompleteDocuments(response.data.incomplete_documents);
  // 				setProgress(response.data.progress);
  // 				setvalue_prev(response.data.progress.overall_percentage);
  // 			}
  // 			setLoaded(true);

  // 		})
  // 		//Then with the error genereted...
  // 		.catch((error) => {
  // 			setLoaded(true);

  // 			console.error("Error:", error);
  // 		});
  // };
  const companyName = uInfo().company_name;
  const words = companyName.split(" ");

  //
  // const fetchDataCoahes = (queryString = "") => {
  // 	setLoaded(false);
  // 	fetch(`${ApiUrl}` + "get/waybook/coach/dashboard/quizStats" + queryString,
  // 		{
  // 			method: "POST",
  // 			headers: {
  // 				Accept: "application/json",
  // 				"Content-Type": "application/json",
  // 				Authorization: `Bearer ` + uToken(),
  // 			},
  // 			body: JSON.stringify({
  // 				user_id: uInfo().user_id
  // 			}),
  // 		})
  // 		.then((response) => response.json())
  // 		.then((response) => {
  // 			if (response.status === true) {
  // 				setCoachesRecords(response.data.records)
  // 				setRecords(response.data.records)
  // 				setTotal_pages(response.data.total)
  // 			}
  // 			setLoaded(true);
  // 		})
  // 		//Then with the error genereted...
  // 		.catch((error) => {
  // 			setLoaded(true);
  // 			console.error("Error:", error);
  // 		});
  // };
  const fetchBookMarkData = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "bookmarked/trainings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ page_size: 5 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setBookmarkData(response.data.records);
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };
  const fetchLeaderBoardData = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/waybook/coach/leaderboard", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ user_id: uInfo().user_id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setLeadBoard(response.data.records);
          setAuthUserRank(response.data.auth_user_rank);
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };
  const fetchModuleData = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/waybook/coach/dashboard/moduleData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        status: 0,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setModuleData(response.data.records);
          if (response.data.records.length > 0) {
            setModulePreview(response.data.records[0]);
          }
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);
        console.error("Error:", error);
      });
  };

  const openBadgesModal = () => {
    console.log('hey');
    setBadgesModal(true);
  };

  return (
    <>
      <Loader loaded={loaded}></Loader>
      <div className="default-layout">
        <div className="dash-welcome-left">
          <div className="row">
            <div className="col-lg-12">
              <div className="TraningRightContent DashboardCard TraningCoachDash">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="DashboardHeading mb-3 text-center">
                      <h3>
                        Good Afternoon {uInfo().first_name}! Welcome to{" "}
                        <span className="PrimaryClass">PROFIT</span>
                        <span className="SecondryClass">COACH</span> Business
                        Center™
                      </h3>
                    </div>
                  </div>
                  {/* Start Coach Dashboard */}
                  <div className="col-lg-4 pe-2">
                    <div className="card mt-3">
                      <div className="card-body ps-2 pb-1 pe-1 DashBordTop">
                        <div className="DashboardHeadingSection mb-4">
                          <h4 className="card-title">Earned Badges</h4>
                        </div>
                        <div className="RecentlyViewed">
                          <ModuleBadges
                            id={uInfo().user_id}
                            openModal={openBadgesModal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {uInfo().type === 'Coach' && (
                  <div className="col-lg-4 px-2">
                    <div class="card mt-3">
                      <div class="card-body ps-2 pe-1 pb-1 DashBordTop">
                        <div class="DashboardHeadingSection mb-4">
                          <h4 class="card-title">Leaderboard</h4>
                        </div>
                        <ul className="IncompleteTraining TraningDash-Board">
                          {lead_board &&
                            lead_board.map((row, n) => (
                              <>
                                {row.user_id === uInfo().user_id ? (
                                  <li className="d-flex align-items-center justify-content-between px-2 auth-user-rank">
                                    <div className="d-flex align-items-start">
                                      {auth_user_rank}. {"You"}
                                    </div>
                                    <div className="PercentageComplete">
                                      {Intl.NumberFormat().format(
                                        row.coaching_points
                                      )}{" "}
                                      CP
                                    </div>
                                  </li>
                                ) : (
                                  <li className="d-flex align-items-center justify-content-between px-2">
                                    <div className="d-flex align-items-start">
                                      {n + 1}. {row.name}
                                    </div>
                                    <div className="PercentageComplete">
                                      {Intl.NumberFormat().format(
                                        row.coaching_points
                                      )}{" "}
                                      CP
                                    </div>
                                  </li>
                                )}
                              </>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                   )}
                  <div className="col-lg-4 pe-2">
                    <div class="card mt-3">
                      <div class="card-body ps-2 pb-1 pe-1 DashBordTop">
                        <div class="DashboardHeadingSection mb-4">
                          <h4 class="card-title">Bookmarks</h4>
                        </div>
                        <ul className="RecentlyViewed BookMark">
                          {bookmark_data &&
                            bookmark_data.map((step, n) => (
                              <li className="d-flex align-items-start text-start">
                                <div className="ms-2">
                                  <h6>
                                    <Link
                                      to={
                                        step.type == "quiz"
                                          ? `/trainingsreview/${step.encoded_link}`
                                          : `/trainings/${step.encoded_link}`
                                      }
                                      style={{ color: "inherit" }}
                                    >
                                      {step.training_title}
                                    </Link>
                                  </h6>
                                  <p>{step.course_title}</p>
                                  <p>
                                    <span>
                                      <i className="mdi mdi-chevron-right"></i>
                                    </span>
                                    {step.module_title}
                                  </p>
                                  <p>
                                    <span>
                                      <i className="mdi mdi-chevron-right"></i>
                                    </span>
                                    {step.lesson_title}
                                  </p>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <h6
                          className="mb-0"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Link to={"/bookmarked/trainings"}>
                            {"Click to jump to bookmarked content"}
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 px-2">
                    <div class="card mt-3">
                      <div class="card-body px-1 pb-1 DashBordTop">
                        <div class="DashboardHeadingSection mb-4">
                          <h4 class="card-title">Courses In Progress</h4>
                        </div>
                        <ul className="IncompleteTraining TraningDash-Board ProgressCourse">
                          {module_data &&
                            module_data.map((row, n) => (
                              <li className={`d-flex align-items-center justify-content-between px-2 ${row.id === module_preview.id ? 'active-course' : ''}`}>

                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setModulePreview(row);
                                  }}
                                >
                                  {row.module_title}
                                  <br></br>
                                  <small>{row.course_title}</small>
                                </div>
                                <div className="PercentageComplete">
                                  {Intl.NumberFormat().format(
                                    row.coaching_points
                                  )}{" "}
                                  CP<br></br>
                                  <small>{row.course_percentage}%</small>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ps-2">
                    <div class="card mt-3">
                      <div class="card-body px-2 pb-1 DashBordTop">
                        <div class="DashboardHeadingSection mb-4">
                          <h4 class="card-title">Module Progress</h4>
                        </div>
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="NobHeading">
                              <h4 >{module_preview.module_title}</h4>
                              <h5>
                                {module_preview.encoded_link && (
                                  <Link
                                    to={`/trainings/${module_preview.encoded_link}`}
                                  >
                                    Click here to start from<br></br>where you let
                                  </Link>
                                 )}
                              </h5>
                            </div>
                          </div>
                          <div className="col-lg-5" onClick={() => setBadgesModal(true)}>
                            <div
                              className="ModuleBadge"
                              onClick={() => setBadgesModal(true)}
                            >
                              {/* <img src='https://cdn.prod.website-files.com/661b25aa8bda4a590a431922/666221640463c81adaf4afba_64a59700883a82beb1983664_Accredible_DigitalCredentialsBlog_02-DigitalBadge1.png'/> */}
                              <img
                                src={`${AttachementsUrl}/user_${module_preview.wl_admin}/training/${module_preview.badge_image}`}
                                alt="Module Badge Preview"
                              />
                              <h5>Module Badge</h5>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Knob
                            className="ProgressKnob"
                            value={module_preview?.overall_percentage}
                            height={100}
                            width={100}
                            fgColor="#008037"
                          />
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-7 pe-1 ps-4">
                            <div className="SetpsQuestions">
                              <ul>
                                <li>
                                  Completed Steps:{" "}
                                  <span>
                                    <b>
                                      {module_preview?.total_completed_steps ??
                                        "0"}
                                    </b>
                                  </span>
                                </li>
                                <li>
                                  Remaining Steps:{" "}
                                  <span>
                                    <b>
                                      {module_preview?.remaining_steps ?? "0"}
                                    </b>
                                  </span>
                                </li>
                                <li>
                                  Completed Quizzes:{" "}
                                  <span>
                                    <b>
                                      {module_preview?.total_completed_quiz ??
                                        "0"}
                                    </b>
                                  </span>
                                </li>
                                <li>
                                  Remaining Quizzes:{" "}
                                  <span>
                                    <b>
                                      {module_preview?.remaining_quiz ?? "0"}
                                    </b>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 ps-2">
                    <div class="card mt-3">
                      <div class="card-body ps-2 pe-1 pb-1 DashBordTop MdulePreview">
                        <div class="DashboardHeadingSection mb-4">
                          <h4 class="card-title">Module Preview</h4>
                        </div>
                        <div className="d-flex">
                          <div className="ModuleImageContent">
                            <h5>Module Objective</h5>
                            <p>{module_preview.description}</p>
                          </div>
                          <div className="ModuleImg">
                            <img
                              style={{ maxWidth: "90%", maxHeight: "100%" }}
                              src={`${AttachementsUrl}/user_${module_preview.wl_admin}/training/${module_preview.image}`}
                              alt="Module Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* End Coach Dashboard */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ALL MODULE BADGES MODAL */}
      <Modal
        isOpen={badgesModal}
        role="dialog"
        centered={true}
        className="exampleModal modal-xl UpdateInformationModal"
        tabIndex="-1"
        toggle={() => {
          setBadgesModal(false);
        }}
      >
        <ModalHeader className="text-transform-capitalize p-2 UpdateInformationUser">
          <div>Profile Badges Progress</div>
          <button
            onClick={() => setBadgesModal(false)}
            className="btn btn-link pull-right BtnCenterClose me-end"
          >
            <i className="fas fa-times"></i>
          </button>
        </ModalHeader>
        <ModalBody>
          <UserProgressProfile />
        </ModalBody>
        <ModalFooter className="">
          <Button
            color="info"
            onClick={() => {
              setBadgesModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
