import React, { useState, useEffect } from "react";
import { ApiUrl, AttachementsUrl } from "../../config";
import Carousel from "react-bootstrap/Carousel";
import { Row, Col, Card, CardBody } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const BadgesWeb = (props) => {
  const [id, setId] = useState(
    props.id ? props.id : props.match.params.id ? props.match.params.id : null
  );

  const [records, setRecords] = useState([]);
  const [total_badge, setTotalBadges] = useState(0);
  const [loading, setLoading] = useState(true);
  const [defaultBadge, setDefaultBadge] = useState({});

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    fetch(`${ApiUrl}get/module/badges`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id, status: "1" }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRecords(response.data.records);
          setTotalBadges(response.data.total);

          const shownBadge = response.data.records.find(
            (badge) => badge.shown_on_web === 1
          );
          if (shownBadge) {
            setDefaultBadge(shownBadge);
          } else if (response.data.records.length > 0) {
            setDefaultBadge(response.data.records[0]);
          }

          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {/* {
                !loading && records.length > 0 ? (
                    <>
                    <Carousel style={{minHeight: '250px'}}>
                        {records.map((record, index) => (
                            <Carousel.Item key={index} style={{}}>
                                <div style={{ textAlign: 'center' }}>
                                    <div className="SliderContent">
                                        <h5 className="ChangeColor" style={{marginBottom: '10px'}}>{record.module_title}</h5>
                                        <h5>{record.course_title}</h5>
                                    </div>
                                    <img className="" src={`${AttachementsUrl}user_${record.wl_admin}/training/${record.image}`} alt={record.module_title} style={{ textAlign: 'center', maxWidth: '100px' }}/>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                     <div style={{ textAlign: 'center' }}>
                        <h4 style={{ textAlign: 'center', color: '#008037', fontSize: '14px' }}>Total Badges: {total_badge}</h4>
                    </div>
                    </>
                ):(
                    <img src="https://ws.profitcoach.app/crmdoc/default/images/certified-logo.png" alt="Icon"></img>
                )
            } */}

      {!loading && records.length > 0 ? (
        <>
          <Card className="mb-0 bg-transparent" style={{}}>
            <CardBody className="p-0" style={{}}>
              <div style={{ textAlign: "center" }}>
                <div className="SliderContent pt-0 mb-3">
                  <h5 className="ChangeColor" style={{ marginBottom: "10px" }}>
                    {defaultBadge.module_title}
                  </h5>
                  <h5>{defaultBadge.course_title}</h5>
                </div>
                <img
                  className=""
                  src={`${AttachementsUrl}user_${defaultBadge.wl_admin}/training/${defaultBadge.image}`}
                  alt={defaultBadge.module_title}
                  style={{ textAlign: "center", maxWidth: "100px" }}
                />
              </div>
            </CardBody>
          </Card>
          {/* <div style={{ textAlign: "center" }}>
            <h4
              style={{
                textAlign: "center",
                color: "#008037",
                fontSize: "14px",
              }}
            >
              Total Badges: {total_badge}
            </h4>
          </div> */}
        </>
      ) : (
        <img
          src="https://ws.profitcoach.app/crmdoc/default/images/certified-logo.png"
          alt="Icon"
        ></img>
      )}
    </>
  );
};

export default BadgesWeb;
