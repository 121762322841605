import React, { useEffect, useState } from "react";
import Helmet from "react-helmet"
import { Link, Redirect, useHistory } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
// reactstrap
import {
  Row,
  Col,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  ModalHeader,
  Progress
} from "reactstrap";

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import OnlineClientDropdown from "../CommonForBoth/TopbarDropdown/OnlineClientDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import classname from "classnames";

import { ApiUrl, logoLightPng, logoLightSmallPng } from "../../config.js";
import useToken, { bcrypt, logout, switchUserList, uInfo, useSwitchUser, uToken, hasRole } from "../../useToken";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { toast, Flip } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import AvField from "availity-reactstrap-validation/lib/AvField";
import ReactSelect from "react-select";
import countries from "../../common/data/countries";

import { HostUrl, PusherKey } from "../../config";
import Pusher from "pusher-js";
import swal from "sweetalert";
import Loader from "react-loader";
import $ from "jquery"
import Knob from "../../pages/Campaign/knob";
import { Collapse as BCollapse } from 'react-bootstrap';
import TraningQuiz from './trainingQuiz';
import TrainingCoachDashboard from '../../pages/Waybook/TrainingCoachDashboard';

const Header = (props) => {
  const [menu, setMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [createmenu, setCreateMenu] = useState(false);
  const [current_training, setCurrentTraining] = useState([]);
  const [current_count, setCurrentCount] = useState(0);
  const [final_training, setFinalTraining] = useState([]);
  const [retake_records, setRetakeRecords] = useState([]);
  const [show, setShow] = useState(false);
  const [header_tracking_code, setHeaderTrackingCode] = useState("");
  const [existing, setExisting] = useState([]);
  // const [assessment_id, setAssessmentId] = useState(0);
  const [isClient, setIsClient] = useState(true);
  const [modal, setModal] = useState(false);
  const [modal_retake, setRetakeModal] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [countryCode, setCountryCode] = useState("+1");
  const [groupId, setGroupId] = useState("");
  const [groups, setGroups] = useState([]);
  const [coachTraining, setCoachTraining] = useState({
    category: {},
    subjects: [],
    current_step: {},
    documents: [],
    questions: [],
    training_quiz_survey: {},
    current_subject_index: 0,
    current_steps_index: 0,
    current_documnet_index: 0,

  });

  const [checkBypass, setCheckByPass] = useState(false);


  const [hideWelcomeNote, setHideWelcomeNote] = useState(true);
  const [open, setOpen] = useState(-1);
  const [openC, setOpenC] = useState(-1);

  const toggleOpen = (id) => {
    setOpen((isOpen) => (isOpen === id ? null : id));
  }

  const toggleOpenC = (id) => {
    setOpenC((isOpenC) => (isOpenC === id ? null : id));
  }

  const [masterCategory, setMasterCategory] = useState([]);
  const [meta, setMeta] = useState(false);
  let history = useHistory();

  const switchUser = switchUserList();

  const { setToken } = useToken();
  const { setSwitchUser } = useSwitchUser();

  useEffect(() => {
    const pusher = new Pusher(PusherKey, {
      cluster: 'mt1'
    })
    const channel1 = pusher.subscribe('my-channel');
    channel1.bind('chat-event', function (data) {
      console.log(data, 'coach header')
      if (
        data.data.sent_to == uInfo().user_id
        && data.data.sub_type == 'video'
      ) {
        swal({
          title: 'Invitation for video chat',
          text: "Click join button to goes on the video link",
          icon: "info",
          buttons: true,
          dangerMode: true,
          buttons: ["Cancel", "Join"],
        }).then((willDelete) => {
          if (willDelete) {
            window.location.href = `${HostUrl}/chat/${data.data.user_id}/${data.data.type}`;
          }
        });
      }
    })

    return (() => {
      pusher.unsubscribe('my-channel')
      // pusher.unsubscribe('channel_name2')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    checkVerfication();
    if (uInfo().is_proposal_accepted == 'No') {
      setTimeout(history.push("/settings/accept-proposal"), 4000);
    }
    console.log(uInfo().retake_action, 'dddddddddddd')
    if (uInfo().retake_action == 1 && uInfo().is_proposal_accepted == 'Yes') {
      getRetakeAction();
    }
    if (uInfo().is_approved == 'Yes' && uInfo().is_proposal_accepted == 'Yes' && uInfo().is_training_completed == 0) {
      getOnboardTraining();
    }
    getGroups();
  }, []);

  useEffect(() => {
    // console.log(coachTraining)

    console.log(uInfo().coach_can_sell_trainings === "active_paid");
  }, []);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const searchClient = (search) => {
    if (search != "") {
      fetch(`${ApiUrl}` + "get/clients", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify({ filter_value: search }),
      })
        .then((response) => response.json())
        //Then with the data from the response in JSON...
        .then((response) => {
          // console.log(response, "data");
          if (response.status === true) {
            setExisting(response.data.records);
            setIsClient(response.data.records.length > 0 ? true : false);
          } else {
            toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

          }
        })
        //Then with the error genereted...
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setExisting([]);
      setIsClient(true);
    }
  };
  const getRetakeAction = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/retake/training", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'retake': 1 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setRetakeModal(true);
          setLoaded(true);
          setRetakeRecords(response.data.records);
        } else {
          setLoaded(true);
        }
      })
      .catch((error) => {
        setLoaded(true);
        console.error("Error:", error);
      });
  };
  const getOnboardTraining = (current_subject_index = '-1', current_documnet_index = '-1', current_steps_index = '-1') => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/onboard/training", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'current_subject_index': current_subject_index, 'current_documnet_index': current_documnet_index, 'current_steps_index': current_steps_index }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // if (uInfo().user_id == 4) {
          //console.log(response.data)
          setCoachTraining(response.data);
          setOpenC(response.data.current_subject_index);
          setOpen(response.data.current_documnet_index);
          // setCurrentTraining(response.data.current_training);
          // setFinalTraining(response.data.final_training);
          // setMasterCategory(response.data.get_master_id);
          if (response.data.category.completed_step > 0 || response.data.category.completed_step == response.data.category.total_steps) {
            setHideWelcomeNote(false);
          }

          if (response.data.category.completed_step != undefined && response.data.category.completed_step == response.data.category.total_steps) {
            console.log(response.data.category.total_steps, 'herer -----------', response.data.category.completed_step)
            if (!checkBypass) {
              finisTraining();
            }
          }
          setModal(true);
          setLoaded(true);

          // }
        } else {
          setLoaded(true);

          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        setLoaded(true);

        console.error("Error:", error);
      });
  };

  const getGroups = () => {
    fetch(`${ApiUrl}` + "groups", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.assessment_id, 'getGroups')
          //setAssessmentId(response.data.assessment_id);
          setGroups(response.data.records);
        } else {
          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (event, values) => {
    // props.navigation.history.push("/client/list");

    fetch(`${ApiUrl}` + "edit/client", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {

        if (data.status === true) {
          let res = data.data;
          //console.log(res.client_id, 'client_id');
          props.navigation.history.push(
            "/lead/dialer/" +
            bcrypt(res.client_id) +
            "/" +
            bcrypt(res.coach_id)
          );
        } else {
          let wrapper = document.createElement('div');
          wrapper.innerHTML = data.message;
          swal({
            title: "Error!",
            content: wrapper,
            icon: "error"
          });
          //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });

        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCountry = (selectedCountry) => {
    setCountryCode(selectedCountry.code);
  };

  const handleGroup = (selectedGroup) => {
    setGroupId(selectedGroup.group_id);
  };

  const checkVerfication = () => {
    fetch(`${ApiUrl}` + "check/verification", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          if (response.data.verification_status == 'N' || response.data.is_approved != 'Yes') {
            setTimeout(history.push("/verification/wizard"), 4000);
          }

          $('#scriptContainer').append(response.data.header_tracking_code);

          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "zr88xkbb",
            name: uInfo().name ?? '',
            email: uInfo().email ?? '',
            created_at: parseInt((new Date(uInfo().created_at).getTime() / 1000).toFixed(0)),
          };
          //setHeaderTrackingCode(response.data.header_tracking_code);
        } else {
          //toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
        setMeta(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const markAsComplete = (val) => {
    setLoaded(false);
    let data = {
      user_id: uInfo().user_id,
      step_id: coachTraining.current_step.step_id,
      cat_id: coachTraining.current_step.cat_id,
    }
    // if (!current_training.user_id) {
    fetch(`${ApiUrl}` + "step/mark/complete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoaded(true);
        if (response.status === true) {
          if (val == 'default') {
            getOnboardTraining();
          }

        } else {
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const finisTraining = (cat_id = 0) => {
    let data = {
      user_id: uInfo().user_id,
      cat_id: cat_id == 0 ? coachTraining.category.cat_id : cat_id,
    }
    fetch(`${ApiUrl}` + "finish/way/welcome/training", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          let newtoken = {
            is_training_completed: 1,
          }
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));
          setModal(false);
        } else {
          console.error("Error:", response.message);
          // toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  };
  const popover = (
    <Popover id="popover-basic" className="client-popover">
      <Popover.Header as="h3">Add a Lead</Popover.Header>
      <Popover.Body>
        <AvForm
          onValidSubmit={handleSubmit}
          // model={this.state.record}
          className="needs-validation"
        >
          <div className="row">
            <div className="col-md-6 mb-3">
              <AvField
                name="first_name"
                label="First Name"
                className="form-control"
              />
            </div>
            <div className="col-md-6 mb-3">
              <AvField
                name="last_name"
                label="Last Name"
                className="form-control"
              />
            </div>

            <div className="col-md-12 mb-3">
              <label className="control-label">Group</label>
              <div className="MainTZone UsageGroup">
                <ReactSelect
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.group_id}
                  value={groups.filter(({ group_id }) => group_id === groupId)}
                  isMulti={false}
                  options={groups}
                  classNamePrefix="select2-selection"
                  name="groups_id"
                  onChange={(e) => {
                    handleGroup(e);
                  }}
                />
              </div>
              <AvField required type="hidden" name="group_id" value={groupId} />
            </div>
            <div className="col-md-12 mb-3">
              <AvField
                name="email"
                label="Email"
                className="form-control"
                type="email"
              // required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="control-label">Country</label>
              <div className="MainTZone">
                <ReactSelect
                  getOptionLabel={(option) =>
                    option.label + " (" + option.code + ")"
                  }
                  getOptionValue={(option) => option.code}
                  value={countries.filter(({ code }) => code === countryCode)}
                  isMulti={false}
                  options={countries}
                  classNamePrefix="select2-selection"
                  name="country_id"
                  onChange={(e) => {
                    handleCountry(e);
                  }}
                />
              </div>
              <AvField
                type="hidden"
                name="country_code"
                value={countryCode}
              />
            </div>
            <div className="col-md-6 mb-3">
              <AvField
                name="mobile"
                label="Mobile"
                className="form-control"
                placeholder="Enter mobile without country code"
                type="number"
                // required
                validate={{
                  // required: { value: true, errorMessage: 'This field is invalid' },
                  pattern: { value: '^([0-9]+([.][0-9]*)?|[.][0-9]+)$', errorMessage: 'Type only positive numbers' },

                }}
              />
            </div>

            <div className="col-md-6 mb-3">
              <a className="btn btn-outline-primary" href="/lead/add">
                Advanced
              </a>
            </div>

            <div className="col-md-6 mb-3">
              <button
                className="btn btn-primary waves-effect waves-light pull-right"
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </AvForm>
      </Popover.Body>
    </Popover>
  );
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }

  const Logout = () => {
    fetch(`${ApiUrl}` + "logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${uToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        logout();
        history.push("/login");
        // if (data.status === true) {
        //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
        // } else {
        //   toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        // }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const UserToggle = (user_id) => {

    fetch(`${ApiUrl}` + 'login/choice', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'user_id': user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((res) => {
        if (res.status === true) {
          setToken(res.data.user);
          setSwitchUser(res.data.users);
          history.push('/');
          // toast.success(res.message, { theme: "colored" });
        } else {
          toast.error(res.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const resetOnBoardTraining = (client_id) => {

    fetch(`${ApiUrl}` + 'reset/onboardTraining', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ 'client_id': client_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((res) => {
        if (res.status === true) {
          getOnboardTraining();
          setCheckByPass(true);

          let newtoken = {
            is_training_completed: 0,
          }
          const authUser = {
            ...JSON.parse(localStorage.getItem("authUser")),
            ...newtoken,
          };
          localStorage.setItem("authUser", JSON.stringify(authUser));

          // toast.success(res.message, { theme: "colored" });
        } else {
          toast.error(res.message, { theme: "colored" });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const closeModal = () => {
    let newtoken = {
      is_training_completed: 1,
    }
    const authUser = {
      ...JSON.parse(localStorage.getItem("authUser")),
      ...newtoken,
    };
    localStorage.setItem("authUser", JSON.stringify(authUser));
    setModal(false);
  }

  const closeRetakeModal = () => {
    let newtoken = {
      retake_action: 0,
    }
    const authUser = {
      ...JSON.parse(localStorage.getItem("authUser")),
      ...newtoken,
    };
    localStorage.setItem("authUser", JSON.stringify(authUser));
    setRetakeModal(false);
  }


  return (
    <React.Fragment>
      {/* <Helmet><script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=745c95b2-741c-4c90-a4ba-a7b29b9d25f3"> </script></Helmet> */}
      <div className="tm-embed-container" id="scriptContainer"></div>
      <div className="dialer">
        <Loader loaded={loaded}></Loader>
      </div>
      <div className="navbar-header">
        <Container fluid className="px-0">

          <Col className="nav-bg px-0 d-flex space-between">
            <div className="float-start">
              <div className="navbar-brand-box d-lg-none">
                <Link to="#" className="logo logo-dark">
                  <span className="logo-sm pl-2">
                    <img src={logoLightSmallPng} alt="" height="50" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="50" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  setCreateMenu(!createmenu)
                }}
                data-target="#topnav-menu-content"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </div>
            <div className="top_navbar">
              <div className="container-fluid">
                <div className="topnav">
                  <nav
                    className="navbar navbar-light navbar-expand-lg topnav-menu"
                    id="navigation"
                  >
                    <Collapse
                      isOpen={props.leftMenu}
                      className="navbar-collapse"
                      id="topnav-menu-content"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <Link to="/dashboard" className="nav-link">
                            {"Dashboard"}
                          </Link>
                        </li>

                        {hasRole(uInfo(), ["clients"]) && (
                          <li className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle arrow-none">
                              {"CRM"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["inbox"]) && (
                                <Link to={"/chat/inbox/" + bcrypt(0) + "/" + bcrypt(0)}
                                  className="dropdown-item">
                                  {"Inbox"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["leads"]) && (
                                <Link to="/lead/list" className="dropdown-item">
                                  {"Leads"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["clients"]) && (
                                <Link to="/client/list" className="dropdown-item">
                                  {"Clients"}
                                </Link>
                              )}
                              {/* <Link to="/company/list" className="dropdown-item">
                              {"Companies"}
                            </Link> */}

                              {
                                hasRole(uInfo(), ["my_coaches"]) && uInfo().has_coach_license == 'Yes' && (
                                  <Link to="/users/coach" className="dropdown-item">
                                    {"My Coaches"}
                                  </Link>
                                )
                              }

                              {hasRole(uInfo(), ["groups_and_tags"]) && (
                                <Link to="/clients/groups" className="dropdown-item">
                                  {"Groups & Tags"}
                                </Link>
                              )}
                              {/* <Link to="/" className="dropdown-item"  >
                     {"Tags"}
                    </Link> */}

                              {hasRole(uInfo(), ["custom_fields"]) && (
                                <Link to="/clients/field/list" className="dropdown-item">
                                  {"Custom Fields"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        {hasRole(uInfo(), ["sales"]) && (
                          <li className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle arrow-none">
                              {"Sales"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["pipeline"]) && (
                                <Link to="/pipeline/list" className="dropdown-item">
                                  {"Pipeline"}
                                </Link>
                              )}
                              {
                                hasRole(uInfo(), ["onboard_training"]) && uInfo().has_coach_license == 'Yes' && (
                                  <Link to="/training/status/list" className="dropdown-item">
                                    {"OnBoard Training"}
                                  </Link>
                                )
                              }

                              <Link to="/lead/forms" className="dropdown-item">
                                {"Lead Forms"}
                              </Link>


                              {/* <Link to="/tasks/list" className="dropdown-item">
                              {"Tasks"}
                            </Link> */}

                              {/* {hasRole(uInfo(), ["contracts"]) && (
                                <Link to="/contract/list" className="dropdown-item">
                                  {"Contracts"}
                                </Link>
                              )} */}


                              {hasRole(uInfo(), ["invoices"]) && (
                                <Link to="/invoice/list" className="dropdown-item">
                                  {"Invoices"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        <div className="navbar-brand-box d-inline-block centerr-logo mx-3">
                          <Link to="/" className="logo logo-dark">
                            <span className="logo-sm pl-2">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                            <span className="logo-lg">
                              <img src={logoLightPng} alt="" height="45" />
                            </span>
                          </Link>
                        </div>
                        {hasRole(uInfo(), ["campaign"]) && (
                          <li className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle arrow-none">
                              {"Campaign"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["campaign_list"]) && (
                                <Link to="/campaign/list" className="dropdown-item">
                                  {"Campaign"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["call_status"]) && (
                                <Link to="/callstatus/list" className="dropdown-item">
                                  {"Call Status"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["caller_group"]) && (
                                <Link to="/callergroup/list" className="dropdown-item">
                                  {"Caller Group"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["template_library"]) && (
                                <Link to="/emailtemplates/list" className="dropdown-item">
                                  {"Templates Library"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["unsubscribe_keywords"]) && (
                                <Link to="/unsubkeywords/list" className="dropdown-item">
                                  {"Unsubscribe Keywords"}
                                </Link>
                              )}
                            </div>
                          </li>
                        )}
                        {hasRole(uInfo(), ["coaching"]) && (
                          <li className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle arrow-none">
                              {"Coaching"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["assessment_list"]) && (
                                <Link to="/assessment/list/all" className="dropdown-item">
                                  {"Assessments"}
                                </Link>
                              )}
                              {/* <Link to="/assessment/list/center" className="dropdown-item">
                              {"Profit Center"}
                            </Link> */}
                              {/* {
                              assessment_id > 0 && (
                                <Link
                                  to={
                                    "/progress/tracker/" +
                                    bcrypt(assessment_id) +
                                    "/scheduling_freedom"
                                    }
                                  className="dropdown-item">
                                  {"Profit Center"}
                                </Link>
                              )
                            } */}

                              {hasRole(uInfo(), ["trainings"]) && (
                                <Link to="/trainings" className="dropdown-item">
                                  {"Trainings"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["training_tags"]) && (
                                <Link to="/training/tags" className="dropdown-item">
                                  {"Training Tags"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["training_group"]) && (
                                <Link to="/traininggroup/list" className="dropdown-item">
                                  {"Group Coaching"}
                                </Link>
                              )}
                              {/* {hasRole(uInfo(), ["assessment_list"]) && (
                                <Link to="/coachTraining" className="dropdown-item">
                                  {"My training"}
                                </Link>
                              )} */}
                              {/* {hasRole(uInfo(), ["trainings"]) && (
                                <Link to="/trainings" className="dropdown-item">
                                  {"Trainings"}
                                </Link>
                              )} */}

                              {hasRole(uInfo(), ["resources"]) && (
                                <Link to={`/resourcesDrive/my-resources/${bcrypt(uInfo().user_id)}`} className="dropdown-item">
                                  {"Resource Center"} <i className="fas fa-arrow-up"></i>
                                </Link>
                              )}
                              {uInfo().coach_can_sell_trainings === "active_paid" && (
                                <Link to={`/training-products`} className="dropdown-item">
                                  {"Training Products"}
                                </Link>
                              )}

                              {/* {hasRole(uInfo(), ["resources"]) && (
                                <Link to="/assesments/resources" className="dropdown-item">
                                  {"Resources"}
                                </Link>
                              )} */}
                            </div>
                          </li>
                        )}
                        {hasRole(uInfo(), ["reports"]) && (
                          <li className="nav-item dropdown">
                            <Link to="#" className="nav-link dropdown-toggle arrow-none">
                              {"Reports"}{" "}
                              <i className="mdi mdi-chevron-right"></i>
                            </Link>
                            <div
                              className={classname(
                                "dropdown-menu dropdown-menu-left",
                                {}
                              )}
                            >
                              {hasRole(uInfo(), ["reporting"]) && (
                                <Link to="/report/summary" className="dropdown-item">
                                  {"Reporting"}
                                </Link>
                              )}
                              {hasRole(uInfo(), ["credit_log"]) && (
                                <Link to="/report/credithistory" className="dropdown-item">
                                  {"Credits Log"}
                                </Link>
                              )}

                            </div>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </nav>
                </div>


              </div>

            </div>
            <div className="float-end">

              {
                uInfo().type == 'Coach' && (
                  <button title="OnBoard training" className="btn" onClick={() => resetOnBoardTraining(uInfo().client_id)}>
                    <i className="fas fa-chalkboard-teacher text-white">
                    </i>
                  </button>
                )
              }


              {/* d-lg-inline-block */}
              <div className="app-search  d-none  search">
                <div className="position-relative d-flex">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => searchClient(e.target.value)}
                    onBlur={() =>
                      setTimeout(() => {
                        setExisting([]);
                      }, 1000)
                    }
                  />
                  <OverlayTrigger
                    rootClose={true}
                    trigger="click"
                    placement="bottom-start"
                    overlay={popover}
                  >
                    <button
                      onClick={() => setShow(!show)}
                      type="button"
                      className="btn"
                    >
                      <i className="fas fa-plus"></i>
                    </button>
                  </OverlayTrigger>
                </div>
                <ul className="client-search-bar">
                  {!isClient && <li className="text-primary">No data found</li>}
                  {existing.map((row, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={
                            "/lead/dialer/" +
                            bcrypt(row.client_id) +
                            "/" +
                            bcrypt(row.coach_id)
                          }
                          target="_parent"
                        >
                          <div className="d-grid-template">
                            <div className="info">
                              <i className="fas fa-user"></i>
                              {row.first_name} {row.last_name}
                            </div>

                            <div className="info">
                              <i className="fas fa-mobile"></i>
                              {row.mobile}
                            </div>
                          </div>

                          <div className="d-grid-template">
                            <div className="info">
                              <i className="fas fa-user"></i> {row.email}
                            </div>

                            <div className="info">
                              <i className="fas fa-phone"></i>
                              {row.phone}
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* d-inline-block d-lg-non */}
              <div className="dropdown ms-2 SearchDropDown">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  onClick={() => setSearch(!isSearch)}
                >
                  <i className="mdi mdi-magnify" />
                </button>
                <div
                  className={
                    isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <div className="app-search d-inline-block search px-3">
                    <div className="position-relative d-flex">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => searchClient(e.target.value)}
                        onBlur={() =>
                          setTimeout(() => {
                            setExisting([]);
                          }, 1000)
                        }
                      />
                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        placement="bottom-start"
                        overlay={popover}
                      >
                        <button
                          onClick={() => setShow(!show)}
                          type="button"
                          className="btn"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>

                    <ul className="client-search-bar">
                      {!isClient && <li className="text-primary">No data found</li>}
                      {existing.map((row, i) => {
                        return (
                          <li key={i}>
                            <Link
                              to={
                                "/lead/dialer/" +
                                bcrypt(row.client_id) +
                                "/" +
                                bcrypt(row.coach_id)
                              }
                              target="_parent"
                            >
                              <div className="d-grid-template">
                                <div className="info">
                                  <i className="fas fa-user"></i>
                                  {row.first_name} {row.last_name}
                                </div>

                                <div className="info">
                                  <i className="fas fa-mobile"></i>
                                  {row.mobile}
                                </div>
                              </div>

                              <div className="d-grid-template">
                                <div className="info">
                                  <i className="fas fa-user"></i> {row.email}
                                </div>

                                <div className="info">
                                  <i className="fas fa-phone"></i>
                                  {row.phone}
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <Link
                to={"/video/room/host"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Group Video Chat"
              >
                <i className="ion ion-md-videocam"></i>
              </Link>

              <Link
              style={{ padding: '4px' }}
                to={"/email/inbox"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Email Inbox"
              >
                <i className="ion ion-ios-mail"></i>
              </Link>
              <Link
                to={"/chat"}
                className="btn header-item noti-icon waves-effect display-inline"
                title="Incoming Chat Message"
              >
                <i className="ion ion-md-chatboxes"></i>
                {props.readCount > 0 && (
                  <span
                    className="badge bg-danger rounded-pill"
                    id={"total_read_count"}
                  >
                    {props.readCount}
                  </span>
                )}
              </Link>

              <OnlineClientDropdown />
              {/* d-lg-inline-block */}
              <div className="dropdown d-none  none">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen font-size-24"></i>
                </button>
              </div>






              <NotificationDropdown />

              <ProfileMenu />




            </div>
          </Col>
        </Container>

      </div >
      <Row>

        <Col sm={12}>
          <div className="top-navigation px-3">
            <Collapse
              isOpen={createmenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    <span className="DashIcon"><i className="ti-dashboard"></i></span> <span>{"Dashboard"}</span>
                  </Link>
                </li>

                {hasRole(uInfo(), ["clients"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'clients' ? '' : 'clients')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      <span className="DashIcon"><i className="mdi mdi-account-box"></i></span> <span>{"Clients"}{" "}
                        <i className="mdi mdi-chevron-right"></i></span>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'clients' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["inbox"]) && (
                        <Link to={"/chat/inbox/" + bcrypt(0) + "/" + bcrypt(0)} className="dropdown-item">
                          {"Inbox"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["leads"]) && (
                        <Link to="/lead/list" className="dropdown-item">
                          {"Leads"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["clients"]) && (
                        <Link to="/client/list" className="dropdown-item">
                          {"Clients"}
                        </Link>
                      )}
                      {
                        hasRole(uInfo(), ["my_coaches"]) && uInfo().has_coach_license == 'Yes' && (
                          <Link to="/users/coach" className="dropdown-item">
                            {"My Coaches"}
                          </Link>
                        )
                      }
                      {hasRole(uInfo(), ["groups_and_tags"]) && (
                        <Link to="/clients/groups" className="dropdown-item">
                          {"Groups & Tags"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["custom_fields"]) && (
                        <Link to="/clients/field/list" className="dropdown-item">
                          {"Custom Fields"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["sales"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'sales' ? '' : 'sales')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Sales"}{" "}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'sales' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["pipeline"]) && (
                        <Link to="/pipeline/list" className="dropdown-item">
                          {"Pipeline"}
                        </Link>
                      )}
                      {
                        hasRole(uInfo(), ["onboard_training"]) && uInfo().has_coach_license == 'Yes' && (
                          <Link to="/training/status/list" className="dropdown-item">
                            {"OnBoard Training"}
                          </Link>
                        )
                      }
                      {hasRole(uInfo(), ["contracts"]) && (
                        <Link to="/contract/list" className="dropdown-item">
                          {"Contracts"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["invoices"]) && (
                        <Link to="/invoice/list" className="dropdown-item">
                          {"Invoices"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["campaign"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'campaign' ? '' : 'campaign')
                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Campaign"}{" "}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'campaign' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["campaign_list"]) && (
                        <Link to="/campaign/list" className="dropdown-item">
                          {"Campaign"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["call_status"]) && (
                        <Link to="/callstatus/list" className="dropdown-item">
                          {"Call Status"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["caller_group"]) && (
                        <Link to="/callergroup/list" className="dropdown-item">
                          {"Caller Group"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["template_library"]) && (
                        <Link to="/emailtemplates/list" className="dropdown-item">
                          {"Templates Library"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["unsubscribe_keywords"]) && (
                        <Link to="/unsubkeywords/list" className="dropdown-item">
                          {"Unsubscribe Keywords"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {hasRole(uInfo(), ["coaching"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'coaching' ? '' : 'coaching')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Coaching"}{" "}
                      <i className="mdi mdi-chevron-right"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'coaching' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["assessment_list"]) && (
                        <Link to="/assessment/list/all" className="dropdown-item">
                          {"Assessments"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["trainings"]) && (
                        <Link to="/trainings" className="dropdown-item">
                          {"Trainings"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["training_tags"]) && (
                        <Link to="/training/tags" className="dropdown-item">
                          {"Training Tags"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["resources"]) && (
                        <Link to={`/resourcesDrive/my-resources/${bcrypt(uInfo().user_id)}`} className="dropdown-item">
                          {"Resources"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}
                {hasRole(uInfo(), ["reports"]) && (
                  <li className="nav-item dropdown">
                    <Link to="/#"
                      onClick={e => {
                        e.preventDefault()
                        setMenu(menu == 'reports' ? '' : 'reports')

                      }}
                      className="nav-link dropdown-toggle arrow-none">
                      {"Reports"}{" "}
                      <i className="mdi mdi-chevron-down"></i>
                    </Link>
                    <div
                      className={`dropdown-menu dropdown-menu-left ${menu == 'reports' ? 'show' : ''}`}
                    >
                      {hasRole(uInfo(), ["reporting"]) && (
                        <Link to="/report/summary" className="dropdown-item">
                          {"Reporting"}
                        </Link>
                      )}
                      {hasRole(uInfo(), ["credit_log"]) && (
                        <Link to="/report/credithistory" className="dropdown-item">
                          {"Credits Log"}
                        </Link>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>

          </div>

        </Col>

      </Row>
      <Modal
        isOpen={modal}
        fade={true}
        size="xl"
        className="survey-content-question PopUpModal ExtraLarge modal-fullscreen"
        backdrop={'static'}
        centered={true}
        toggle={() => {
          setModal(false);
        }}
      >
        <Loader loaded={loaded}></Loader>
        {/* <div className="modal-title nav-bg container-fluid justify-content-center position-relative">
          <div className="navbar-brand-box d-flex centerr-logo m-auto text-center justify-content-center">
            <Link to="/" className="logo logo-dark">
              <span className="logo-lg">
                <img src={logoLightPng} alt="" height="45" />
              </span>
            </Link>
          </div>
          <button type="button" className="btn btn-md btn-link p-0 PopupClose" onClick={() => closeModal()}>
            <i className="fas fa-times" /> Close
          </button>
        </div> */}


        <ModalBody>
          <div className="row">
            <Row className="mx-0">
              <Col xl={6} className="ps-0">
                <div style={{ width: '250px', float: 'left', display: 'flex', justifyContent: 'start' }}>
                  <button type="button" className="btn btn-md btn-link btn-block p-1 ps-0" onClick={() => closeModal()}>
                    <i className="fas fa-times" /> Close
                  </button>
                </div>
                <div className="logout-wizard-training d-flex">
                  {/* {
                    switchUser.map((user, i) => {
                      if (uInfo().user_id != user.user_id && uInfo().staff_user_id == null) {

                        return (
                          <button
                            key={i}
                            onClick={() => UserToggle(user.user_id)}
                            className="btn btn-md text-left"
                          >
                            <i className="mdi mdi-account-switch font-size-17 text-muted align-middle me-1" />
                            <span>Switch To {user.type == "WlAdmin" ? "Admin" : user.type}</span>
                          </button>
                        )
                      }
                    })
                  }
                  <button type="button" className="btn btn-md btn-link" onClick={() => Logout()}>
                    <i className="fas fa-power-off" /> Logout
                  </button> */}


                </div>
              </Col>
              <Col xl={6}>

                <div className="text-align-right training-progress PopUpProgress testing">

                  <Progress color="primary" title={coachTraining.category.percentage}
                    value={coachTraining.category.percentage}>
                    {coachTraining.category.percentage}%<div></div></Progress>
                </div>

              </Col>
            </Row>
            <div className="PointsContent">
              {
                coachTraining.category.completed_step == coachTraining.category.total_steps && !checkBypass ?

                  <div className="row">
                    {
                      coachTraining.category.cat_id ?
                        <>

                          <div className="col-lg-4 offset-2 mt-5">
                            <div className="alert alert-success mt-5">
                              <p>You mark all your training complete, click the finish button and start using the system</p>
                            </div>
                          </div>
                          <div className="col-lg-4 offset-2 mt-5">
                            <div className='postion-absolute'>
                              <Knob
                                value={(coachTraining.category.completed_step / coachTraining.category.total_steps) * 100}
                                fgColor="#4ac18e"
                                title={"All documents complete."}
                                readOnly={true}
                                height={200}
                                width={200}
                                onChange={e => {
                                  console.log(e)
                                }}
                              />
                            </div>
                          </div>
                        </>
                        :
                        <>
                          <div className="col-lg-8 offset-2 mt-5">
                            <div className="alert alert-warning mt-5">
                              <p>Not getting any training at this time. We are adding trainig you can see later.</p>
                            </div>
                          </div>
                        </>
                    }


                  </div>
                  :
                  <div className="start-Training">
                    {
                      coachTraining.category.completed_step == 0 && hideWelcomeNote && (
                        <div className="w-100 d-flex justify-content-center m-auto">
                          <div className="row">
                            <div className="col-lg-9">
                              <div className="video-welcome-msg">
                              <TrainingCoachDashboard />
                                {/* <div className="video-lesson" dangerouslySetInnerHTML={{ __html: coachTraining.category.welcome_body }} /> */}
                              </div>
                            </div>
                            <div className="col-lg-3 mt-5">
                              <div className='postion-absolute'>
                                <Knob
                                  value={coachTraining.category.percentage + '%'}
                                  title={"Complete all documents to reach 100%. You are currently " + coachTraining.category.percentage + "% complete."}
                                  fgColor="#4ac18e"
                                  thickness={0.12}
                                  readOnly={true}
                                  height={200}
                                  width={200}
                                  onChange={e => {
                                    console.log(e)
                                  }}
                                />
                              </div>
                              <Button
                                  type="button"
                                  color="primary"
                                  className="w-75 mt-3 btn btn-primary m-auto d-flex justify-content-center"
                                  onClick={() => setHideWelcomeNote(false)}
                                >
                                  LET'S BEGIN!
                                </Button>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    {
                      (coachTraining.category.completed_step > 0 || !hideWelcomeNote) && (

                        <Row className="ms-0">
                          <Col lg="3" className="pl-0">
                            <div className={`welcome-training code-blue mb-1`}><h4 className="card-title">
                              {hideWelcomeNote ? coachTraining.category.title
                                :
                                coachTraining.current_step.title ?? ''
                              }
                            </h4></div>
                            <ul className="popupSideBar onborading">
                              {coachTraining.subjects.map((rowC, c) => {
                                return (
                                  <li key={'s-' + c} className={`documents`}>
                                    <div className="document-popup" >

                                      <button className='dropdown-item'
                                        onClick={() => toggleOpenC(c)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={openC == c ? true : false}
                                        disabled={coachTraining.current_subject_index >= c || c > 0 && (coachTraining.subjects[c - 1].total_steps == coachTraining.subjects[c - 1].completed_step) ? false : true}
                                      >

                                        {openC == c ? <i className='fas fa-caret-down pe-2' /> : <i className='fas fa-caret-right pe-2 ' />}
                                        <span>{rowC.title}</span>

                                      </button>

                                      <BCollapse className={`collapse ${openC == c ? 'show' : ''}`}>
                                        <div className="document-step-dropdown">
                                          <ul className="popupSideBar1 p-0">
                                            {rowC.documents.map((row, i) => {
                                              return (
                                                <li key={'d-' + i} className={`documents`}>
                                                  <div className="document-popup"
                                                  >

                                                    <button className='dropdown-item'
                                                      onClick={() => toggleOpen(i)}
                                                      aria-controls="example-collapse-text"
                                                      aria-expanded={open == i ? true : false}
                                                      disabled={(coachTraining.current_documnet_index >= i || row.total_steps == row.total_complete_steps) ? false : true}
                                                    >
                                                      {open == i ? <i className='fas fa-caret-down pe-2' /> : <i className='fas fa-caret-right pe-2 ' />}
                                                      <span>{row.title}</span>

                                                    </button>

                                                    <BCollapse className={`collapse ${open == i ? 'show' : ''}`}>
                                                      <div className="document-step-dropdown">
                                                        <ul className="popupSideBar1 p-0">
                                                          {
                                                            row.steps.map((step, s) => {
                                                              return (
                                                                <li key={'step-' + s} className={``}>

                                                                  <button className={`dropdown-item ${step.log_id ? ('completed') : ''} ${coachTraining.current_step.step_id == step.step_id ? ('activeStep') : ''}`}
                                                                    onClick={() => getOnboardTraining(c, i, s)}
                                                                    disabled={(coachTraining.current_steps_index >= s || step.log_id) ? false : true}
                                                                  >
                                                                    <span>{step.title}</span>
                                                                    {/* {step.log_id && (
                                                            <span className="fas fa-check float-end ms-1"></span>
                                                          )} */}
                                                                  </button>
                                                                </li>
                                                              )
                                                            })
                                                          }
                                                        </ul>
                                                      </div>
                                                    </BCollapse>
                                                  </div>
                                                </li>
                                              )
                                            })
                                            }
                                          </ul>
                                        </div>
                                      </BCollapse>

                                    </div>
                                  </li>
                                )
                              })
                              }
                            </ul>
                          </Col>
                          <Col lg="9" className="ps-0">
                            <Row>
                              <Col className="col-12">
                                <Card className="NoShadow HeightAuto PopUpDataShow ChangeForPopup">
                                  <Row className="mx-0">
                                    <Col xl={12} className="p-1">
                                      <Card
                                        className="NoShadow traning  VideoHeight"
                                        style={{ borderRight: 0 }}
                                      >
                                        <CardBody>
                                          {coachTraining.current_step.type === 'quiz' ?
                                            <>
                                              <TraningQuiz questions={coachTraining.questions} trainingquizsurvey={coachTraining.training_quiz_survey} cat_id={coachTraining.current_step.cat_id} step_id={coachTraining.current_step.step_id} />
                                            </>
                                            : <><div className="video-lesson" dangerouslySetInnerHTML={{ __html: coachTraining.current_step.content }} /></>}
                                        </CardBody>

                                      </Card>
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    }
                  </div>

              }

            </div>
          </div>
        </ModalBody>
        <ModalFooter className="w-100 ">
          {/* {
                !hideWelcomeNote && ( */}
          <Row>
            <Col lg={3}></Col>
            <Col lg={9} className="ps-3">
              {

                coachTraining.category.completed_step == coachTraining.category.total_steps
                  ?
                  coachTraining.category.cat_id && (
                    <>
                      {
                        !checkBypass && (
                          finisTraining(coachTraining.category.cat_id)
                        )
                      }

                      {
                        checkBypass && (
                          <>

                            {/*  <Button
                             type="button"
                             color="primary"
                             onClick={() => finisTraining()}
                           >
                             Finish
                           </Button> */}
                          </>
                        )
                      }

                    </>


                  )

                  :

                  <>

                    {
                      !hideWelcomeNote && (
                        <>
                          {
                            coachTraining.current_step.log_id ?
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => { getOnboardTraining(); setCheckByPass(false); }}
                              >
                                Next
                              </Button>
                              :
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => { markAsComplete('default'); setCheckByPass(false) }}
                              >
                                Next
                              </Button>
                          }
                        </>
                      )
                    }
                  </>


              }


            </Col>
          </Row>
          {/* )
              } */}


        </ModalFooter>
      </Modal>

      {/* Retake Model */}
      <Modal
        isOpen={modal_retake}
        fade={true}
        size="xl"
        className="survey-content-question PopUpModal ExtraLarge modal-fullscreen"
        backdrop={'static'}
        centered={true}
        toggle={() => {
          setRetakeModal(false);
        }}
      >
        <Loader loaded={loaded}></Loader>

        <ModalBody>
          <div className="row">
            <Row className="mx-0">
              <Col xl={12} className="ps-0">
                <div style={{ width: '250px', float: 'left', display: 'flex', justifyContent: 'start' }}>
                  <button type="button" className="btn btn-md btn-link btn-block p-1 ps-0" onClick={() => closeRetakeModal()}>
                    <i className="fas fa-times" /> Close
                  </button>
                </div>
                <div className="logout-wizard-training d-flex">
                </div>
              </Col>
            </Row>
            <div className="PointsContent">
              <Row>
                <Col md="12">
                  <div className="card-body">
                    <div className="h4 card-title mb-3">Training Retake Actions </div>
                    {retake_records && retake_records.map((item, index) => (
                      <div className='alert alert-info alert fade show' role="alert">
                        <p>
                          New Training &nbsp; <b>{item.title}</b> &nbsp; retake acton added  on  {item?.created_at}
                        </p>
                      </div>
                    ))}

                  </div>
                </Col>
              </Row>

            </div>
          </div>
        </ModalBody>
        <ModalFooter className="w-100 ">

          <Row>
            <Col lg={3}></Col>
            <Col lg={9} className="ps-3">
              {

                coachTraining.category.completed_step == coachTraining.category.total_steps
                  ?
                  coachTraining.category.cat_id && (
                    <>
                      {
                        !checkBypass && (
                          finisTraining(coachTraining.category.cat_id)
                        )
                      }

                      {
                        checkBypass && (
                          <>

                            {/*  <Button
                             type="button"
                             color="primary"
                             onClick={() => finisTraining()}
                           >
                             Finish
                           </Button> */}
                          </>
                        )
                      }

                    </>


                  )

                  :

                  <>

                    {
                      !hideWelcomeNote && (
                        <>
                          {
                            coachTraining.current_step.log_id ?
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => { getOnboardTraining(); setCheckByPass(false); }}
                              >
                                Next
                              </Button>
                              :
                              <Button
                                type="button"
                                color="primary"
                                onClick={() => { markAsComplete('default'); setCheckByPass(false) }}
                              >
                                Next
                              </Button>
                          }
                        </>
                      )
                    }
                  </>


              }


            </Col>
          </Row>

        </ModalFooter>
      </Modal>

    </React.Fragment >
  );
};

export default Header;
