import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import ClientHeader from "./ClientHeader";
import CoachHeader from "./CoachHeader";
import SuperAdminHeader from "./SuperAdminHeader";
import AdminHeader from "./AdminHeader";

import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Pusher from 'pusher-js'
import { ApiUrl, PusherKey } from "../../config";
import { uInfo, uToken } from "../../useToken";

let pusher = new Pusher(PusherKey, {
  cluster: "mt1"
})
var channel = pusher.subscribe('my-channel');

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
      readCount: 0,
    };
    this.role = { id: this.props.children.props.role ? this.props.children.props.role.id : 0 };
    //console.log(this.props.children.props.role.id, 'data'); 
  }

  componentDidMount() {
    var self = this;
    channel.bind("chat-event", function (data) {
      if (data.data.sent_to == uInfo().user_id) {
        if (self != undefined) {
          self.setState({
            readCount: data.data.count
          })
        }
      }
    });

    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }

    this.getUnReadCount();
    // Scrollto 0,0
    window.scrollTo(0, 0);
  }

  getUnReadCount = () => {
    fetch(`${ApiUrl}` + "get/unread/count", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((data) => {
        if (data.status === true) {
          this.setState({
            readCount: data.data.un_read,
          });
        } else {
          console.error("Error:", data.message);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  /**
   * Opens the menu - mobile
   */
  openMenu = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };
  render() {
    const styles = {
      position: 'fixed !important',

    };
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>

        <div id="layout-wrapper" className={window.location.pathname.match('/waybook') ? 'layout-wrapper waybook-page' : 'layout-wrapper'}>
          <header id="page-topbar">

            {uInfo().type == "SuperAdmin" && (
              <SuperAdminHeader
                isMenuOpened={this.state.isMenuOpened}
                openLeftMenuCallBack={this.openMenu.bind(this)}
                readCount={this.state.readCount}
                navigation={this.props.children.props}
                navbar={<div className="top-navigation">
                  <div className="page-title-content"></div>
                </div>}
                roleid={this.role.id}
              />
            )}
            {uInfo().type == "WlAdmin" && (
              <AdminHeader
                isMenuOpened={this.state.isMenuOpened}
                openLeftMenuCallBack={this.openMenu.bind(this)}
                readCount={this.state.readCount}
                navigation={this.props.children.props}
                navbar={<div className="top-navigation">
                  <div className="page-title-content"></div>
                </div>}
                roleid={this.role.id}
              />
            )}
            {(uInfo().type == "Coach" || uInfo().type == "CoachPartner") && (
              <CoachHeader
                isMenuOpened={this.state.isMenuOpened}
                openLeftMenuCallBack={this.openMenu.bind(this)}
                readCount={this.state.readCount}
                navigation={this.props.children.props}
                navbar={<div className="top-navigation">
                  <div className="page-title-content"></div>
                </div>}
                roleid={this.role.id}
              />
            )}
            {uInfo().type == "Client" && (
              <ClientHeader
                isMenuOpened={this.state.isMenuOpened}
                openLeftMenuCallBack={this.openMenu.bind(this)}
                readCount={this.state.readCount}
                navigation={this.props.children.props}
                navbar={<div className="top-navigation">
                  <div className="page-title-content"></div>
                </div>}
                roleid={this.role.id}
              />
            )}



          </header>
          {/* <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          /> */}
          <div className="main-content">
            <div className={`page-content ${window.location.pathname.match('/chat') ? 'chat' : window.location.pathname.match('/video/room/host') ? 'chat group-chat'
              : window.location.pathname.match('/company/dialer') || window.location.pathname.match('/lead/dialer') ||
                window.location.pathname.match('/client/dialer') ? 'dialer' : ''} ${window.location.pathname.match('/waybook') ? 'waybook-content' : ''} ${window.location.pathname.match('/resourcesDrive') ? 'resourcesDrive' : ''} ${window.location.pathname.match('/trainings') ? 'way-training-bg' : ''} ${window.location.pathname.match('/coachTraining') ? 'way-training-bg' : ''} ${window.location.pathname.match('/email/inbox') ? 'MailInbox' : ''} ${window.location.pathname.match('/email/compose') ? 'MailInbox' : ''} ${window.location.pathname.match('/email/sent') ? 'MailInbox' : ''} ${window.location.pathname.match('/email/read') ? 'MailInbox' : ''}`}>
              <Container style={{ maxWidth: "100%",width: "100%" }}>
                {this.props.children}
                <Footer style={styles} />
              </Container>
            </div>
          </div>
        </div>
        {/*    {this.props.showRightSidebar ? <Rightbar /> : null} */}
      </React.Fragment>
    );
  }
}

export default Layout;
